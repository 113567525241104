import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
const contact = () => {
  return (
    <Layout>
      <SEO title='Contact' description='This is the contact page' />
      <section className='contact-page'>
        <article className='contact-form'>
          <h3>Newsletter Sign up</h3>
          <form
            className="contact-form form-group"
            name="Newsletter"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/success"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Newsletter" />

            <input
              type="text"
              name="name"
              placeholder="name"
              className="form-control "
            />
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="email"
            />
            <button type="submit" className="btn submit-btn">
              Sign up
            </button>
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact
